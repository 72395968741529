/*
*获取未遵守差标要求的原因
*/
const __request = require(`./__request/__request_contentType_json`);

const consumer_trains_common_queryBusinessOverweightBecause = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/common/queryBusinessOverweightBecause',
        data: pParameter
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_common_queryBusinessOverweightBecause;