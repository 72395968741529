/*
*获取酒店政策列表-分长短期和统一差标
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/updateHotelTravelPolicy',
        data: data
    }
    return __request(pParameter)
}
export default request