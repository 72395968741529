import PositionLevelChoose from '@/component/positionLevelChoose/index.vue'
import getHotelPolicyDetail from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getHotelPolicyDetail';
import getClassifyHotelPolicyDetail
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getClassifyHotelPolicyDetail';
import getNoncomplianceReason
    from '@/lib/data-service/haolv-default/consumer_trains_common_queryBusinessOverweightBecause';
import addTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addTravelPolicy';
import addHotelTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addHotelTravelPolicy';
import updatePolicyByBusinessType
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_updatePolicyByBusinessType';
import updateHotelTravelPolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_updateHotelTravelPolicy';
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'//查询退改政策
import consumer_web_policyConfig_getCityClassSetList from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getCityClassSetList';
import consumer_web_policyConfig_getUsingCityTemplateList from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getUsingCityTemplateList'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'

export default {
    name: "editHotelStandards",
    data() {
        return {
            loading: false,
            policyIds: [],
            positionVoArr: [],
            positionVoIdList: [],
            templateIdList: [],
            hotelPolicyObj: {
                templateId: '',
                hotelControlRules: 11,
                hotelPolicy: 1,
                priceList: [],
                shortPolicy: {
                    id: 0,
                    // tripDays: '',
                    hotelPolicy: 1,
                    hotelControlRules: 11,
                    firstCityRestrictions: '',
                    secondCityRestrictions: '',
                    thirdCityRestrictions: '',
                    elseCityRestrictions: '',
                    fourCityRestrictions: '',
                    fiveCityRestrictions: '',
                    templateId: '',
                },
                longPolicy: {
                    id: 0,
                    // tripDays: '',
                    hotelPolicy: 1,
                    hotelControlRules: 11,
                    firstCityRestrictions: '',
                    secondCityRestrictions: '',
                    thirdCityRestrictions: '',
                    elseCityRestrictions: '',
                    fourCityRestrictions: '',
                    fiveCityRestrictions: '',
                    templateId: '',
                },
                sharedPercentage: '',
            },
            sharedPercentageRadio: 1,
            noncomplianceReasons: [],
            submitParams: {},
            isShareRoom: false,
            ifLongShortTrip: 0,//是否长-短期出差标准 0：否 1：是
        }
    },
    components: {
        PositionLevelChoose
    },
    async mounted() {
        this.loading = true;
        this.sharedPercentageRadio = 1;
        const policyIds = this.$route.query.policyIds;
        if (policyIds) {
            this.policyIds = JSON.parse(this.$route.query.policyIds);
        } else {
            this.policyIds = [];
        }

        await this._getCityClassList();
        this._getPosition();
        this._getNoncomplianceReason();
        if (this.policyIds.length > 0) {
            this.ifLongShortTrip = Number(this.$route.query.longShortBusiness);
            this._getHotelPolicyDetail();
        } else {
            this.getRefundChangePolicy();
        }
    },
    methods: {
        //获取酒店价格模板列表
        async _getCityClassList() {
            let [err, res] = await awaitWrap(consumer_web_policyConfig_getUsingCityTemplateList());
            if (err) {
                return
            }
            let templateList = res.datas.templateList;
            templateList.forEach(value => {
                value.sortList.forEach(value1 => {
                    value1.price = '';
                })
            })
            this.templateIdList = templateList
            console.log(this.templateIdList)
        },

        //初始化使用职级组件
        _getPosition() {
            this.$refs.positionChoose.init({
                id: this.policyIds.length === 0 ? 0 : this.policyIds[0],
                type: this.policyIds.length === 0 ? 'add' : 'edit',
                businessType: 3
            })
        },

        //获取酒店政策详情
        async _getHotelPolicyDetail() {
            let res = null;
            if (this.ifLongShortTrip === 0) {
                res = await getHotelPolicyDetail({
                    id: this.policyIds[0]
                });
            } else {
                res = await getClassifyHotelPolicyDetail({
                    policyIds: this.policyIds
                });
            }
            let hotelPolicyObj = res.datas;
            hotelPolicyObj.priceList = hotelPolicyObj.priceResultList || [];
            delete hotelPolicyObj['priceResultList'];
            if (hotelPolicyObj.priceList.length === 0 && hotelPolicyObj.templateId) {
                // 将模板的数组赋值上去
                this.templateIdList.forEach(value => {
                    if (value.templateId === hotelPolicyObj.templateId) {
                        hotelPolicyObj.priceList = JSON.parse(JSON.stringify(value.sortList))
                    }
                })
            } else if (hotelPolicyObj.priceList.length > 0 && hotelPolicyObj.templateId) {
                // 13991 【铨成商旅】酒店模板删除部分城市后，对应已在使用的职级差别没有删除对应的类别城市
                let currentTemplate = [];
                this.templateIdList.forEach(value => {
                    if (value.templateId === hotelPolicyObj.templateId) {
                        currentTemplate = JSON.parse(JSON.stringify(value.sortList))
                    }
                })
                currentTemplate.forEach(value => {
                    value.price = '';
                    hotelPolicyObj.priceList.forEach(value1 => {
                        if (value.sortId === value1.sortId) {
                            value.price = value1.price;
                        }
                    })
                })
                hotelPolicyObj.priceList = currentTemplate;
            }
            this.isShareRoom = hotelPolicyObj.sharedStatus == 1 ? true : false;
            this.hotelPolicyObj = hotelPolicyObj;
            this.loading = false;
        },

        //改变酒店价格模板
        changeHotelTemplate(val) {
            console.log(val);
            for (let i = 0, l = this.templateIdList.length; i < l; i++) {
                if (this.templateIdList[i].templateId === val) {
                    this.hotelPolicyObj.priceList = this.templateIdList[i].sortList
                }
            }
        },

        //超标原因可选项
        async _getNoncomplianceReason() {
            let res = await getNoncomplianceReason({
                businessType: 3
            });
            this.noncomplianceReasons = res.datas;
        },

        //根据业务类型修改对应的出差政策
        async _updatePolicyByBusinessType() {
            this.getSubmitParams();
            if (this.ifLongShortTrip === 0) {
                await updatePolicyByBusinessType(this.submitParams);
            } else {
                await updateHotelTravelPolicy(this.submitParams);
            }
        },

        //增加出差政策
        async _addTravelPolicy() {
            this.getSubmitParams();
            if (this.ifLongShortTrip === 0) {
                await addTravelPolicy(this.submitParams);
            } else {
                await addHotelTravelPolicy(this.submitParams);
            }
        },

        //获取退改政策
        async getRefundChangePolicy() {
            let res = await refund_change_policy_get();
            this.ifLongShortTrip = res.datas.ifLongShortTrip;
            this.loading = false;
        },

        //检查表单
        checkSubmissionConditions() {
            this.positionVoArr.forEach(value => {
                if (value.isEcho) {
                    this.positionVoIdList.push(value.id)
                }
            })
            if (this.positionVoIdList.length === 0) {
                this.$message({
                    type: "warning",
                    message: '请先选择适用职级'
                })
                return false;
            }
            if(this.isShareRoom &&
                (this.hotelPolicyObj.sharedPercentage == null || this.hotelPolicyObj.sharedPercentage == '')) {
                this.$message({
                    type: "warning",
                    message: '请设置合住差标上浮比例'
                })
                return false;
            }
            if(this.hotelPolicyObj.sharedPercentage > 100) {
                this.$message({
                    type: "warning",
                    message: '合住差标上浮比例需为小于或等于100的正整数'
                })
                return false;
            }
            if ((this.ifLongShortTrip === 0 && this.hotelPolicyObj.hotelPolicy === 2 && !this.hotelPolicyObj.templateId) ||
                (this.ifLongShortTrip === 1 && this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 && !this.hotelPolicyObj.shortPolicy) ||
                (this.ifLongShortTrip === 1 && this.hotelPolicyObj.longPolicy.hotelPolicy === 2 && !this.hotelPolicyObj.longPolicy)) {
                this.$message({
                    type: "warning",
                    message: '请选择模板'
                })
                return false;
            }
            let hasBlankPrice = false;
            if (this.ifLongShortTrip === 0 && this.hotelPolicyObj.hotelPolicy === 2) {
                this.hotelPolicyObj.priceList.forEach(value => {
                    if (value.price === '') {
                        hasBlankPrice = true
                    }
                })
            } else if (this.ifLongShortTrip === 1 && this.hotelPolicyObj.shortPolicy.hotelPolicy === 2) {
                if ((this.hotelPolicyObj.shortPolicy.firstCityRestrictions === '' ||
                    this.hotelPolicyObj.shortPolicy.secondCityRestrictions === '' ||
                    this.hotelPolicyObj.shortPolicy.thirdCityRestrictions === '' ||
                    this.hotelPolicyObj.shortPolicy.fourCityRestrictions === '' ||
                    this.hotelPolicyObj.shortPolicy.fiveCityRestrictions === '' ||
                    this.hotelPolicyObj.shortPolicy.elseCityRestrictions === '')) {
                    hasBlankPrice = true
                }
            } else if (this.ifLongShortTrip === 1 && this.hotelPolicyObj.longPolicy.hotelPolicy === 2) {
                if ((this.hotelPolicyObj.longPolicy.firstCityRestrictions === '' ||
                    this.hotelPolicyObj.longPolicy.secondCityRestrictions === '' ||
                    this.hotelPolicyObj.longPolicy.thirdCityRestrictions === '' ||
                    this.hotelPolicyObj.longPolicy.fourCityRestrictions === '' ||
                    this.hotelPolicyObj.longPolicy.fiveCityRestrictions === '' ||
                    this.hotelPolicyObj.longPolicy.elseCityRestrictions === '')) {
                    hasBlankPrice = true
                }
            }
            if (hasBlankPrice) {
                this.$message({
                    type: "warning",
                    message: '请输入酒店价格'
                })
                return false;
            } else {
                return true;
            }
        },

        //获取提交参数
        getSubmitParams() {
            let params = {};
            switch (this.ifLongShortTrip) {
                case 0://只有一种出差标准
                    params = {
                        businessType: 3,
                        addHotelPolicy: {
                            templateId: this.hotelPolicyObj.hotelPolicy === 2 ? this.hotelPolicyObj.templateId : '',
                            hotelControlRules: this.hotelPolicyObj.hotelPolicy === 2 ? this.hotelPolicyObj.hotelControlRules : 11,
                            hotelPolicy: this.hotelPolicyObj.hotelPolicy,
                            priceList: this.hotelPolicyObj.priceList,
                            sharedStatus: this.isShareRoom ? 1 : 2,
                            sharedPercentage: this.hotelPolicyObj.sharedPercentage,
                        },
                        id: this.policyIds.length === 0 ? 0 : this.policyIds[0],
                        positionIds: this.positionVoIdList,
                        exceedMsg: this.hotelPolicyObj.exceedMsg,
                    };
                    break;
                case 1://有短期和长期两种出差标准 - 已废弃
                    params = {
                        positionIds: this.positionVoIdList,
                        shortPolicy: {
                            templateId: this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.shortPolicy.templateId : '',
                            firstCityRestrictions: this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.shortPolicy.firstCityRestrictions : '',
                            secondCityRestrictions: this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.shortPolicy.secondCityRestrictions : '',
                            thirdCityRestrictions: this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.shortPolicy.thirdCityRestrictions : '',
                            fourCityRestrictions: this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.shortPolicy.fourCityRestrictions : '',
                            fiveCityRestrictions: this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.shortPolicy.fiveCityRestrictions : '',
                            elseCityRestrictions: this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.shortPolicy.elseCityRestrictions : '',
                            hotelControlRules: this.hotelPolicyObj.shortPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.shortPolicy.hotelControlRules : 11,
                            hotelPolicy: this.hotelPolicyObj.shortPolicy.hotelPolicy,
                            id: this.policyIds.length === 0 ? 0 : this.policyIds[0],
                            // tripDays: Number(this.hotelPolicyObj.longPolicy.tripDays)
                        },
                        longPolicy: {
                            templateId: this.hotelPolicyObj.longPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.longPolicy.templateId : '',
                            firstCityRestrictions: this.hotelPolicyObj.longPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.longPolicy.firstCityRestrictions : '',
                            secondCityRestrictions: this.hotelPolicyObj.longPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.longPolicy.secondCityRestrictions : '',
                            thirdCityRestrictions: this.hotelPolicyObj.longPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.longPolicy.thirdCityRestrictions : '',
                            fourCityRestrictions: this.hotelPolicyObj.longPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.longPolicy.fourCityRestrictions : '',
                            fiveCityRestrictions: this.hotelPolicyObj.longPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.longPolicy.fiveCityRestrictions : '',
                            elseCityRestrictions: this.hotelPolicyObj.longPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.longPolicy.elseCityRestrictions : '',
                            hotelControlRules: this.hotelPolicyObj.longPolicy.hotelPolicy === 2 ? this.hotelPolicyObj.longPolicy.hotelControlRules : 11,
                            hotelPolicy: this.hotelPolicyObj.longPolicy.hotelPolicy,
                            id: this.policyIds.length === 0 ? 0 : this.policyIds[1],
                            // tripDays: Number(this.hotelPolicyObj.longPolicy.tripDays)
                        }
                    };
                    break
                default:
                    break;
            }
            this.submitParams = params;
        },

        //提交
        async submit() {
            this.loading = true;
            if (this.checkSubmissionConditions()) {
                if (this.policyIds.length === 0) {
                    await this._addTravelPolicy();
                } else {
                    await this._updatePolicyByBusinessType();
                }
                this.loading = false;
                this.goToHotelStandardList();
            } else {
                this.loading = false;
            }
        },

        //跳转到酒店政策列表
        goToHotelStandardList() {
            this.$router.push({name: 'admin-hotel-standard-list'});
        }
    },
    watch: {
        positionVoArr(newVal) {
            console.log('newVal-positionVoArr', newVal)
        }
    }
}
